<!--
  作者：顾先生
-->
<template>
  <div id="publicBottom" class="clearfix">
    <div class="publicBottomBox">
      <img src="../assets/image/logo.png" class="headBox2_dingWei1_img">
      <div class="headBox2_dingWei1_p1">
        <div class="headBox2_dingWei1_span1">— &nbsp;&nbsp;&nbsp;&nbsp;鲸诚信&nbsp;&nbsp;&nbsp;&nbsp; —</div>
        <div class="headBox2_dingWei1_span2">诚信赢未来工程</div>
        <div class="headBox2_dingWei1_span3">jingchengxinyong.cn</div>
      </div>
    </div>
    <div class="publicBottomBox_p1">运营单位：河北鲸诚信用服务有限公司</div>
    <div class="publicBottomBox_p1">©版权所有：河北鲸诚信用服务有限公司&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;备案号：冀ICP备2024072650号-5&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;客服电话：400-056-5663&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;公众号：鲸诚信</div>
    <div class="publicBottomBoxBorder"></div>
    <div class="publicBottomBox_p2">
      <img src="../../src/assets/image/ji.png" class="publicBottomBox_p2_img">
      <a href="https://beian.mps.gov.cn/#/query/webSearch?code=13060602001723" rel="noreferrer" target="_blank">冀公网安备13060602001723</a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'publicBottom',
  // 注册组件
  components: {},
  // 注册方法
  methods: {},
  // 父子传值
  props: {},
  // 数据渲染
  data () {
    return {

    }
  },
  // 计算属性
  computed: {},
  // 监听器
  watch: {},
  // 页面初次解析完成
  created () {
  },
  // 页面内容渲染完成
  mounted () {}
}
</script>

<style scoped lang="less">
#publicBottom{
  padding-bottom: 20px;
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  background-image: linear-gradient(0deg,
  #c62727 0%,
  #f94141 100%);
}
.publicBottomBox{
  margin: 30px auto 0;
  height: 90px;
  display: flex;
  align-items: center;
  .headBox2_dingWei1_img{
    width: 100px;
    height: 100%;
    margin-right: 20px;
  }
  .headBox2_dingWei1_p1{
    .headBox2_dingWei1_span1{
      text-align: center;
      font-size: 18px;
      color: #ffffff;
    }
    .headBox2_dingWei1_span2{
      font-size: 20px;
      color: #ffffff;
    }
    .headBox2_dingWei1_span3{
      font-size: 15px;
      color: #ffffff;
    }
  }
}
.publicBottomBox_p1{
  margin: 20px auto 0;
  font-size: 16px;
  color: #ffffff;
}
.publicBottomBoxBorder{
  width: 1200px;
  height: 1px;
  margin: 10px auto 0;
  background-color: #acacac;
}
.publicBottomBox_p2{
  margin: 10px auto 0;
  font-size: 16px;
  display: flex;
  align-items: center;
  a{
    color: #ffffff !important;
  }
  .publicBottomBox_p2_img{
    margin-right: 10px;
    width: 22px;
    height: 22px;
  }
}
</style>
