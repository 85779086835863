<!--
  作者：顾先生
-->
<template>
  <div>
    <div class="wapper headerContentBox">
      <div class="xinYongContent_BoxFlex">
        <title1 title="企业信用公示"></title1>
        <div class="xinYongContent_Box" v-show="isShow">
          <input type="text" v-model="companyName" placeholder="请输入企业全称查询公示情况" class="xinYongContent_input">
          <el-button class="xinYongContent_btn" icon="el-icon-search" @click="chaKan(companyName)">搜索</el-button>
        </div>
      </div>
      <div class="zhengShu" v-show="!isShow">
        <el-button class="zhengShu_btn" type="danger" plain icon="el-icon-arrow-left" @click="Dianji">返回</el-button>
        <div class="zhengShuBoxFlex">
          <div class="zhengShuBox1">
            <div class="zhengShuBox1_p1">承诺单位名称:{{ companyInfoS.zs_company_name }}</div>
            <div class="zhengShuBox1_p2">签署日期:{{ companyInfoS.zs_riqi }}</div>
          </div>
          <div class="zhengShuBox2">
            <img src="../assets/zhengshu/chengnuo_logo1.png" class="zhengShuBox2_logo1">
            <div class="zhengShuBox2_p1">{{ companyInfoS.leixing }}证书</div>
            <img src="../assets/zhengshu/hengxian.png" class="hengxianImg">
            <div class="zhengShuBox2_p2">证书类型：{{ companyInfoS.leixing }}</div>
            <div class="zhengShuBox2_p2">检测平台：鲸诚信-诚信赢未来工程平台</div>
            <div class="zhengShuBox2_p2">信用机构：{{ companyInfoS.zs_jigou }}</div>
            <div class="zhengShuBox2_p2">证书编号：{{ companyInfoS.zs_zhengshu }}</div>
            <div class="zhengShuBox2_p2">到期日期：{{ companyInfoS.zs_qixian }}</div>
            <img src="../assets/zhengshu/hengxian.png" class="hengxianImg">
            <div class="zhengShuBox2_p3">企业基本信息</div>
            <div class="zhengShuBox2_p2">名<span style="opacity: 0;">书类</span>称：{{ companyInfoS.zs_company_name }}</div>
            <div class="zhengShuBox2_p2">社会信用统一代码：{{ companyInfoS.zs_company_code }}</div>
            <div class="zhengShuBox2_p2">法定代表人：{{ companyInfoS.zs_company_idn }}</div>
            <div class="zhengShuBox2_p2">注册日期：{{ companyInfoS.zs_company_time }}</div>
            <div class="zhengShuBox2_p2">经营场所：{{ companyInfoS.zs_company_address }}</div>
            <div class="zhengShuBox2_p2">经营范围：<span class="zhengShuBox2_p2_fanwei">{{ companyInfoS.zs_company_fanwei }}</span></div>
            <div class="zhengShuBox2_Flex">
              <div class="zhengShuBox2_erweima" style="opacity: 0"></div>
              <img src="../assets/zhengshu/zheng1.png" class="zhengShuBox2_Flex_img">
            </div>
          </div>
        </div>
      </div>
      <div class="headerContentBox1_pad" v-show="isShow">
        <div class="headerContentBox1_pad_p1">
          <span class="headerContentBox1_pad_p1_span">（企业信息更新可能会有延迟，如遇错误，请及时联系我们更新）</span>
        </div>
        <div class="headerContentBox1_padBox">诚信赢未来工程企业公示</div>
        <div class="headerContentBox1_BGc">
          <img src="../assets/image/zuojiao.png" class="headerContentBox1_BGc_img1">
          <img src="../assets/image/youjiao.png" class="headerContentBox1_BGc_img2">
          <div class="headerContentBox2">
            <img src="../assets/image/bitaotiico1.png" class="headerContentBox2_img">
            <div class="headerContentBox2_p">诚信赢未来工程企业公示</div>
          </div>
          <div class="headerContentBox1">
            <table>
              <thead>
              <tr>
                <th>公司名称</th>
                <th>统一社会信用代码/注册号</th>
                <th>加入时间</th>
                <th>到期时间</th>
                <th>查看详情</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="user in users" :key="user.id">
                <td>{{ user.zs_company_name }}<br><span class="pos1">已签署诚信承诺书</span><span class="pos2">承诺诚信经营企业</span></td>
                <td>{{ user.zs_company_code }}</td>
                <td>{{ user.zs_riqi }}</td>
                <td>{{ user.zs_qixian }}</td>
                <td><el-button type="danger" @click="chaKan(user.zs_company_name)">查看</el-button></td>
              </tr>
              </tbody>
            </table>
          </div>
          <div class="headerContentBox3">当前仅随机展示近期加入工程的企业，如需查询更多，请搜索框查询！</div>
        </div>
      </div>
      <PublicHref></PublicHref>
    </div>
    <PublicBottom></PublicBottom>
  </div>

</template>

<script>
import title1 from '@/components/title1.vue'
import PublicHref from '@/components/PublicHref.vue'
import PublicBottom from '@/components/publicBottom.vue'
import { chakanCompany, companyInfo } from '@/http/api/user'

export default {
  name: 'headerContent',
  // 注册组件
  components: { PublicBottom, PublicHref, title1 },
  // 注册方法
  methods: {
    Dianji() {
      this.isShow = true
    },
    async chaKan(companyName) {
      if (companyName.trim() === '') {
        this.searchCompany = ''
        return this.$message.error('请输入公司名称')
      }
      window.scrollTo(0, 300)
      const data = await chakanCompany({
        title: companyName
      })
      if (data.msg === '没有符合条件的数据') {
        return this.$message.error(data.msg)
      }
      this.isShow = false
      this.companyInfoS = data.data
      console.log(data.data)
    },
    // 记录不用管
    async companyInfo () {
      const { data } = await companyInfo({
        size: 10,
        page: 1
      })
      this.users = data.data
    }
  },
  // 父子传值
  props: {},
  // 数据渲染
  data () {
    return {
      companyInfoS: {},
      companyName: '',
      isShow: true,
      users: []
    }
  },
  // 计算属性
  computed: {},
  // 监听器
  watch: {},
  // 页面初次解析完成
  created () {
    this.companyInfo()
  },
  // 页面内容渲染完成
  mounted () {}
}
</script>

<style scoped lang="less">
.headerContentBox{
  margin: 0 auto 0;
  .xinYongContent_BoxFlex{
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .xinYongContent_Box{
    margin-top: 20px;
    margin-right: 20px;
    width: 350px;
    height: 40px;
    border: 2px solid #e23743;
    border-radius: 8px;
    background-color: #fff;
    display: flex;
    align-items: center;
    .xinYongContent_input{
      margin-left: 10px;
      width: 270px;
      height: 30px;
      font-size: 14px;
      border: none;
    }
    .xinYongContent_btn{
      display: flex;
      align-items: center;
      justify-content: center;
      width: 64px;
      height: 30px;
      color: white;
      font-size: 12px;
      background-color: #dd1216;
    }
  }
  .zhengShu{
    position: relative;
    margin: 40px 0 40px 0;
    padding: 30px 0 0 0;
    min-height: 700px;
    .zhengShu_btn{
      position: absolute;
      right: 30px;
      top: -60px;
    }
    .zhengShuBoxFlex{
      padding: 10px;
      display: flex;
      .zhengShuBox1{
        position: relative;
        width: 650px;
        height: 390px;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
        border-radius: 10px;
        background-image: url("../assets/zhengshu/zhengshu.png");
        background-repeat: no-repeat;
        background-size: 100% 100%;
        .zhengShuBox1_img{
          position: absolute;
          width: 200px;
          bottom: 35px;
          left: 20px;
        }
        .zhengShuBox1_p1
        ,.zhengShuBox1_p2{
          position: absolute;
          font-size: 12px;
          color: #696968;
        }
        .zhengShuBox1_p1,
        .zhengShuBox1_p2{
          left: 50px;
        }
        .zhengShuBox1_p1{
          bottom: 80px;
        }
        .zhengShuBox1_p2{
          bottom: 60px;
        }
      }
      .zhengShuBox2{
        text-align: justify;
        position: relative;
        padding-bottom: 70px;
        margin-left: 30px;
        width: 700px;
        background-image: url("../assets/zhengshu/chengnuoshu.png");
        background-repeat: no-repeat;
        background-size: 100% 100%;
        .zhengShuBox2_logo1{
          display: block;
          margin: 80px auto 0;
          width: 172px;
        }
        .hengxianImg{
          display: block;
          width: 170px;
          margin: 22px auto 8px;
        }
        .zhengShuBox2_p1{
          font-size: 40px;
          letter-spacing: 3px;
          margin-top: 20px;
          font-weight: bolder;
          text-align: center;
          color: #cf413a;
        }
        .zhengShuBox2_p2{
          width: 430px;
          margin-top: 10px;
          font-size: 14px;
          margin-left: 85px;
        }
        .zhengShuBox2_p2_fanwei{
          font-size: 12px;
        }
        .zhengShuBox2_Flex{
          display: flex;
          align-items: center;
          .zhengShuBox2_erweima{
            margin-left: 85px;
            margin-right: 20px;
            margin-top: 20px;
            width: 90px;
            height: 90px;
            background-color: #4a4949;
          }
          .zhengShuBox2_Flex1
          ,.zhengShuBox2_Flex2{
            margin-top: 30px;
            margin-left: 20px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
          }
          .zhengShuBox2_Flex_img{
            height: 90px;
            margin-left: 30px;
          }
        }
        .zhengShuBox2_p3{
          font-size: 18px;
          letter-spacing: 2px;
          margin-top: 20px;
          font-weight: 900;
          text-align: center;
          color: #cf413a;
        }
      }
    }
  }
  .headerContentBox1_pad{
    position: relative;
    margin-top: 30px;
    margin-bottom: 30px;
    padding: 7px;
    background-color: #fff;
    .headerContentBox1_pad_p1{
      position: absolute;
      left: 245px;
      top: 6.5px;
      display: flex;
      .headerContentBox1_pad_p1_span{
        margin-top: 18px;
        font-size: 13px;
        color: #ffffff;
      }
    }
    .headerContentBox1_padBox{
      display: flex;
      align-items: center;
      font-size: 18px;
      color: #ffffff;
      height: 50px;
      letter-spacing: 2px;
      text-indent: 1em;
      background-image: url("../assets/image/bgc.png");
      background-repeat: no-repeat;
      background-size: 100% 100%;
    }
  }
  .headerContentBox1_BGc{
    position: relative;
    padding: 10px 10px 20px 10px;
    margin-top: 20px;
    background-image: linear-gradient(180deg,
    #ffeee7 0%,
    #fff7f7 100%);
    .headerContentBox1_BGc_img1{
      position: absolute;
      left: 0;
      top: 0;
    }
    .headerContentBox1_BGc_img2{
      position: absolute;
      right: 0;
      top: 0;
    }
  }
  .headerContentBox2{
    position: relative;
    margin-top: 30px;
    background-color: #fff;
    height: 40px;
    .headerContentBox2_img{
      width: 30px;
      position: absolute;
      left: 400px;
      top: -11px;
      z-index: 1;
    }
    .headerContentBox2_p{
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      top: -20px;
      text-align: center;
      width: 500px;
      height: 45px;
      background-image: url("../assets/image/biaoti.png");
      background-repeat: no-repeat;
      background-size: 100% 100%;
      font-size: 25px;
      line-height: 45px;
      color: #ffffff;
      border-radius: 20px;
    }
  }
  .headerContentBox3{
    margin-top: 20px;
    text-align: center;
    font-size: 13px;
    color: #000000;
    opacity: 0.61;
  }
  .headerContentBox1{
    background-color: #fff;
    th{
      padding: 15px;
      font-size: 18px;
      color: #7a7a7a;
    }
    tbody{
      .pos1
      ,.pos2{
        display: inline-block;
        margin-top: 10px;
        transform: scale(.9);
        padding: 2px 5px;
        text-align: center;
        font-size: 12px;
      }
      .pos1{
        color: #0084ff;
        border: solid 1px #0084ff;
      }
      .pos2{
        border: solid 1px #e73d4a;
        color: #e73d4a;
      }
      tr:nth-child(2n-1){
        background-color: #fff3f3;
      }
    }
    td{
      padding: 20px;
      font-size: 15px;
      color: #7a7a7a;
    }
    table{
      border-collapse: collapse;
      border: 0;
      text-align: center;
      width: 100%;
    }
  }
}
</style>
